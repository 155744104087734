import React from "react";
import Social01 from "/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Assets/Social01.png";
import Social02 from "/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Assets/Social02.png";
import Social03 from "/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Assets/Social03.png";
import Social04 from "/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Assets/Social04.png";

const Footer = () => {
  return (
    <div style={{ position: "relative", zIndex: "99999999999" }}>
      <div className="section-indian-red">
        <div className="container mx-auto max-w-4xl px-7 text-center py-20">
          <h2
            className="text-3xl md:text-3xl lg:text-4xl"
            style={{ color: "#D4FF43" }}
          >
            THE END IS NEAR, HURRY UP!
          </h2>

          <div className="flex sm:flex-row justify-center gap-10 pt-20 pb-20 items-center">
            <a
              href="https://discord.gg/2XRH3cW6U7"
              target="_blank"
              alt="discord"
            >
              <img src={Social01} alt="social" className="w-16" />
            </a>
            <a
              href="https://twitter.com/Dinowordsnft"
              target="_blank"
              alt="twitter"
            >
              <img src={Social02} alt="social" className="w-16" />
            </a>
            <a
              href="https://opensea.io/collection/dinowords"
              target="_blank"
              alt="open sea"
            >
              <img src={Social03} alt="social" className="w-16" />
            </a>
            <a
              href="https://medium.com/@dinowordsnft"
              target="_blank"
              alt="medium"
            >
              <img src={Social04} alt="social" className="w-16" />
            </a>
          </div>
          <p
            className="font-bold text-sm md:text-md lg:text-lg text-center pb-7"
            style={{ color: "#D4FF43" }}
          >
            Terms & Conditions | Privacy <br />
            <br />
            For all press inquiries, please contact{" "}
            <a
              href="mailto:info@dinowords.com"
              target="_blank"
              style={{ textDecoration: "none", color: "#D4FF43" }}
            >
              info@dinowords.com
            </a>
            <br /> @ 2022 Dinowords
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
